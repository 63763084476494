import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../redux/store";
import {
  Modal,
  Box,
  Typography,
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  useMediaQuery,
  Theme,
  Radio,
  RadioGroup,
  Alert,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FilterOptionsState,
} from "@mui/material";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { Autocomplete } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import {
  enrollAttendee,
  fetchUsers,
  fetchAttendees,
  fetchSchedules,
} from "../../../redux/instructor/instructorOperations";
import { User, Attendee, Schedule, Class } from "../../../redux/types/types";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getProfile } from "../../../redux/auth/authOperations";
import LatinTextField from "../../../helpers/latinTextField";
import EmailTextField from "../../../helpers/EmailTextField";
import "react-phone-number-input/style.css";
import { isValidPostalCode } from "postal-code-validator";
import PhoneInput from "react-phone-number-input";
import CustomTextField from "../../CustomTextField/CustomTextField";

interface EnrollmentModalProps {
  open: boolean;
  modalClose: any;
  onClose: () => void;
  classItem: Class | null;
  onEnrollmentSuccess?: () => void;
}

type UserData =
  | number
  | {
      email: string;
      firstName?: string;
      firstname?: string;
      lastName?: string;
      lastname?: string;
      phone?: string;
      dataUsageAgreed: boolean;
      photoAgreed: boolean;
    };
type AttendeeData =
  | number
  | {
      firstName: string;
      lastName: string;
      birthdate: string | Date;
      sex: "male" | "female" | "other";
      phone: string;
      email: string;
      postalCode: string;
      permisStatus: string;
    };

interface FormValues {
  user: UserData | null;
  attendee: AttendeeData | null;
  scheduleIds: number[];
}

const EnrollmentModal: React.FC<EnrollmentModalProps> = ({
  open,
  onClose,
  classItem,
  modalClose,
  onEnrollmentSuccess,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("sm")
  );

  const [users, setUsers] = useState<User[]>([]);
  const [attendees, setAttendees] = useState<Attendee[]>([]);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedAttendee, setSelectedAttendee] = useState<Attendee | null>(
    null
  );
  const [isNewUser, setIsNewUser] = useState(false);
  const [isNewAttendee, setIsNewAttendee] = useState(false);
  const [backendError, setBackendError] = useState<string | null>(null);
  const [isWaitlistAlert, setIsWaitlistAlert] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [date, setDate] = useState<string | null>(null);
  // const [time, setTime] = useState<string | null>(null);

  const clearAllStates = () => {
    setSelectedUser(null);
    setSelectedAttendee(null);
    setIsNewUser(false);
    setIsNewAttendee(false);
    setBackendError(null);
    setIsWaitlistAlert(false);
    setActiveStep(0);
    setAttendees([]);
    setSchedules([]);
    setDate(null);
    // setTime(null);
  };

  useEffect(() => {
    if (open && classItem) {
      dispatch(fetchUsers()).then((action) => {
        if (fetchUsers.fulfilled.match(action)) {
          setUsers(action.payload);
        }
      });
      dispatch(fetchSchedules(classItem.id)).then((action) => {
        if (fetchSchedules.fulfilled.match(action)) {
          setSchedules(action.payload);
        }
      });
    }
  }, [open, classItem, dispatch]);

  useEffect(() => {
    if (selectedUser && selectedUser.id) {
      dispatch(fetchAttendees({ userId: selectedUser.id })).then((action) => {
        if (fetchAttendees.fulfilled.match(action)) {
          setAttendees(action.payload);
        }
      });
    }
  }, [selectedUser, dispatch]);

  const validationSchema = Yup.object().shape({
    user: Yup.mixed().required(
      intl.formatMessage({ id: "enrollmentModal.userRequired" })
    ),
    attendee: Yup.mixed().required(
      intl.formatMessage({ id: "enrollmentModal.attendeeRequired" })
    ),
    scheduleIds: Yup.array()
      .of(Yup.number())
      .min(1, intl.formatMessage({ id: "enrollmentModal.scheduleRequired" })),
  });

  const initialValues: FormValues = {
    user: null,
    attendee: null,
    scheduleIds: [],
  };

  const handleSubmit = async (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    setBackendError(null);
    setIsWaitlistAlert(false);
    if (values.user !== null && values.attendee !== null) {
      const attendeeData =
        typeof values.attendee === "number"
          ? values.attendee
          : {
              ...values.attendee,
              birthdate: new Date(values.attendee.birthdate),
            };

      try {
        let from = date
          ? new Date(new Date(date).setHours(0, 0))
          : new Date(new Date().setHours(0, 0));
        // if (time) {
        //   const [hours, minutes] = time.split(":").map(Number);
        //   from.setHours(hours);
        //   from.setMinutes(minutes);
        // }
        const result = await dispatch(
          enrollAttendee({
            user: values.user,
            attendee: attendeeData,
            scheduleIds: values.scheduleIds,
            language: intl.locale,
            from,
          })
        ).unwrap();
        await dispatch(getProfile());
        if (onEnrollmentSuccess) {
          onEnrollmentSuccess();
        }
        clearAllStates();
        onClose();
        if (
          (result.waitingListEntries && result.waitingListEntries.length > 0) ||
          result.message ===
            "One or more schedules are full; you have been added to the waiting list for the corresponding classes."
        ) {
          setIsWaitlistAlert(true);
        }
      } catch (error: any) {
        let errorMessage = intl.formatMessage({
          id: "enrollmentModal.generalError",
        });
        console.log(error);

        if (error) {
          errorMessage = getErrorMessageKey(error);
        }

        setBackendError(intl.formatMessage({ id: errorMessage }));
      } finally {
        setSubmitting(false);
      }
    }
  };

  const getErrorMessageKey = (error: string | string[]): string => {
    const errorMsg = Array.isArray(error) ? error[0] : error;

    if (errorMsg.includes("Attendee is already enrolled in all schedules")) {
      return "enrollmentModal.alreadyEnrolledInAll";
    } else if (errorMsg.includes("Attendee is already enrolled")) {
      return "enrollmentModal.alreadyEnrolled";
    } else if (errorMsg.includes("One or more schedules are full")) {
      return "enrollmentModal.schedulesFull";
    } else if (errorMsg.includes("One or more schedules not found")) {
      return "enrollmentModal.schedulesNotFound";
    } else if (
      errorMsg.includes("A unique constraint violation occurred on email.")
    ) {
      return "attendees.emailAlreadyExists";
    } else if (errorMsg.includes("User with email ")) {
      return "error.emailInUse";
    } else {
      return "enrollmentModal.generalError";
    }
  };

  const renderSchedules = (
    scheduleIds: number[],
    setFieldValue: (field: string, value: any) => void
  ) => {
    const weekdays = [
      intl.formatMessage({ id: "days.monday" }),
      intl.formatMessage({ id: "days.tuesday" }),
      intl.formatMessage({ id: "days.wednesday" }),
      intl.formatMessage({ id: "days.thursday" }),
      intl.formatMessage({ id: "days.friday" }),
      intl.formatMessage({ id: "days.saturday" }),
      intl.formatMessage({ id: "days.sunday" }),
    ];
    const groupedSchedules = weekdays
      .map((day, index) => ({
        day,
        schedules: schedules.filter((schedule) => {
          if (schedule.date) {
            return (
              (new Date(schedule.date).getUTCDay() === 0
                ? 6
                : new Date(schedule.date).getUTCDay() - 1) === index
            );
          }

          return schedule.dayOfWeek === index;
        }),
      }))
      .filter(({ schedules }) => schedules.length > 0);

    return groupedSchedules.map(({ day, schedules }) => (
      <Box key={day} sx={{ mb: 2 }}>
        <Typography variant="subtitle2">{day}</Typography>
        {schedules.map((schedule) => (
          <FormControlLabel
            key={schedule.id}
            control={
              <>
                <Checkbox
                  checked={scheduleIds.includes(schedule.id)}
                  onChange={(e) => {
                    const newScheduleIds = e.target.checked
                      ? [...scheduleIds, schedule.id]
                      : scheduleIds.filter((id) => id !== schedule.id);
                    setFieldValue("scheduleIds", newScheduleIds);
                  }}
                />
                {schedule.date && (
                  <FormattedDate
                    value={schedule.date}
                    year="numeric"
                    month="long"
                    day="numeric"
                  />
                )}
              </>
            }
            label={`${schedule.date ? "/" : ""}${schedule.startTime} - ${
              schedule.endTime
            }`}
          />
        ))}
      </Box>
    ));
  };

  const validateStep = (step: number, values: FormValues, errors: any) => {
    switch (step) {
      case 0:
        if (isNewUser) {
          return (
            !errors.user &&
            values.user &&
            typeof values.user === "object" &&
            values.user.email &&
            values.user.firstName &&
            values.user.lastName &&
            values.user.phone &&
            values.user.dataUsageAgreed
          );
        }
        return values.user !== null;
      case 1:
        if (!values.user) return false;
        if (isNewAttendee) {
          return (
            !errors.attendee &&
            values.attendee &&
            typeof values.attendee === "object" &&
            values.attendee.firstName &&
            values.attendee.lastName &&
            values.attendee.birthdate &&
            values.attendee.sex &&
            values.attendee.phone &&
            values.attendee.email &&
            values.attendee.postalCode &&
            values.attendee.permisStatus
          );
        }
        return (
          values.attendee !== null &&
          (typeof values.attendee === "number" ||
            (typeof values.attendee === "object" &&
              Object.keys(values.attendee).length > 0))
        );
      case 2:
        return values.scheduleIds.length > 0;
      default:
        return true;
    }
  };

  const customFilterOptions = (
    options: User[],
    { inputValue }: FilterOptionsState<User>
  ): User[] => {
    const query = inputValue.toLowerCase().trim();
    if (!query) return options;

    const queryTokens = query.split(/\s+/);

    return options.filter((user) => {
      const userFirstName = user.firstname ? user.firstname.toLowerCase() : "";
      const userLastName = user.lastname ? user.lastname.toLowerCase() : "";
      const fullName = `${userFirstName} ${userLastName}`.trim();

      const email = user.email ? user.email.toLowerCase() : "";

      const userMatch = queryTokens.every(
        (token) => fullName.includes(token) || email.includes(token)
      );

      const attendeesMatch = user.attendees?.some((attendee) => {
        const attendeeFirstName = attendee.firstName
          ? attendee.firstName.toLowerCase()
          : "";
        const attendeeLastName = attendee.lastName
          ? attendee.lastName.toLowerCase()
          : "";
        const attendeeFullName =
          `${attendeeFirstName} ${attendeeLastName}`.trim();
        return queryTokens.every((token) => attendeeFullName.includes(token));
      });

      return userMatch || attendeesMatch;
    });
  };

  const filterOptions = (
    options: User[],
    state: FilterOptionsState<User>
  ): User[] => {
    return customFilterOptions(options, state);
  };

  const steps = [
    {
      title: intl.formatMessage({ id: "enrollmentModal.selectUser" }),
      content: (
        values: FormValues,
        setFieldValue: (field: string, value: any) => void,
        touched: any,
        errors: any
      ) => (
        <>
          {!isNewUser ? (
            <>
              <Autocomplete<User>
                options={users}
                filterOptions={filterOptions}
                noOptionsText={intl.formatMessage({ id: "common.noOptions" })}
                getOptionLabel={(option) =>
                  `${option.firstname} ${option.lastname} (${option.email})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: "enrollmentModal.selectUser",
                      defaultMessage: "Select User",
                    })}
                    fullWidth
                  />
                )}
                onChange={(_, value: User | null) => {
                  setSelectedUser(value);
                  setFieldValue("user", value ? value.id : null);
                  setSelectedAttendee(null);
                  setFieldValue("attendee", null);
                  setIsNewAttendee(false);

                  if (value && value.id) {
                    dispatch(fetchAttendees({ userId: value.id })).then(
                      (action: any) => {
                        if (fetchAttendees.fulfilled.match(action)) {
                          setAttendees(action.payload);
                        }
                      }
                    );
                  } else {
                    setAttendees([]);
                  }
                }}
                value={selectedUser}
              />
              <Button
                onClick={() => {
                  setIsNewUser(true);
                  setSelectedUser(null);
                  setAttendees([]);
                  setSelectedAttendee(null);
                }}
                sx={{ mt: 1 }}
              >
                <FormattedMessage
                  id="enrollmentModal.createNewUser"
                  defaultMessage="Create New User"
                />
              </Button>
            </>
          ) : (
            <>
              <Field
                as={EmailTextField}
                name="user.email"
                label={intl.formatMessage({ id: "signUp.email" })}
                fullWidth
                margin="normal"
                required
                validate={(value: any) =>
                  Yup.string()
                    .email(intl.formatMessage({ id: "signUp.invalidEmail" }))
                    .required(
                      intl.formatMessage({ id: "signUp.emailRequired" })
                    )
                    .validate(value)
                    .then(() => undefined)
                    .catch((err) => err.message)
                }
              />
              <Field
                as={LatinTextField}
                name="user.firstName"
                label={intl.formatMessage({
                  id: "signUp.firstName.input",
                })}
                fullWidth
                margin="normal"
                required
                validate={(value: any) =>
                  Yup.string()
                    .required(
                      intl.formatMessage({ id: "signUp.firstNameRequired" })
                    )
                    .validate(value)
                    .then(() => undefined)
                    .catch((err) => err.message)
                }
              />
              <Field
                as={LatinTextField}
                name="user.lastName"
                label={intl.formatMessage({
                  id: "signUp.lastName.input",
                })}
                fullWidth
                margin="normal"
                required
                validate={(value: any) =>
                  Yup.string()
                    .required(
                      intl.formatMessage({ id: "signUp.lastNameRequired" })
                    )
                    .validate(value)
                    .then(() => undefined)
                    .catch((err) => err.message)
                }
              />
              <div style={{ margin: "16px 0" }}>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  value={
                    (typeof values.user === "object" && values.user?.phone) ||
                    ""
                  }
                  onChange={(value) => setFieldValue("user.phone", value)}
                  inputComponent={CustomTextField as any}
                  error={typeof values.user === "object" && errors.user?.phone}
                />
                {errors.user?.phone && (
                  <Typography color="error" variant="caption">
                    {errors.user?.phone}
                  </Typography>
                )}
              </div>
              {/* <Field name="user.dataUsageAgreed"> */}
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        typeof values.user === "object" &&
                        values.user?.dataUsageAgreed
                      }
                      onChange={() =>
                        setFieldValue(
                          "user.dataUsageAgreed",
                          typeof values.user === "object" &&
                            !values.user?.dataUsageAgreed
                        )
                      }
                      color="primary"
                    />
                  }
                  label={intl.formatMessage({
                    id: "signUp.dataUsageAgreed",
                  })}
                />
                {touched.user?.dataUsageAgreed &&
                  errors.user?.dataUsageAgreed && (
                    <FormHelperText error>
                      {errors.user?.dataUsageAgreed}
                    </FormHelperText>
                  )}
              </div>
              {/* </Field> */}
              {/* <Field name="user.photoAgreed"> */}
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        typeof values.user === "object" &&
                        values.user?.photoAgreed
                      }
                      onChange={() =>
                        setFieldValue(
                          "user.photoAgreed",
                          typeof values.user === "object" &&
                            !values.user?.photoAgreed
                        )
                      }
                      color="primary"
                    />
                  }
                  label={intl.formatMessage({ id: "signUp.photoAgreed" })}
                />
                {touched.user?.photoAgreed && errors.user?.photoAgreed && (
                  <FormHelperText error>
                    {errors.user?.photoAgreed}
                  </FormHelperText>
                )}
              </div>
              {/* </Field> */}
              <Button
                onClick={() => {
                  setIsNewUser(false);
                  setFieldValue("user", null);
                  setSelectedAttendee(null);
                  setFieldValue("attendee", null);
                  setIsNewAttendee(false);
                  setAttendees([]);
                }}
                sx={{ mt: 1 }}
              >
                <FormattedMessage
                  id="enrollmentModal.selectExistingUser"
                  defaultMessage="Select Existing User"
                />
              </Button>
            </>
          )}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: "enrollmentModal.selectAttendee" }),
      content: (
        values: FormValues,
        setFieldValue: (field: string, value: any) => void,
        touched: any,
        errors: any
      ) => (
        <>
          {!isNewAttendee ? (
            <>
              <Autocomplete
                options={[
                  {
                    id: "userData",
                    firstName: "",
                    lastName: "",
                    email: "",
                  },
                  ...attendees,
                ]}
                getOptionLabel={(option) =>
                  option.id === "userData"
                    ? intl.formatMessage({
                        id: "enrollmentModal.userData",
                      })
                    : `${option.firstName} ${option.lastName} (${option.email})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={intl.formatMessage({
                      id: "enrollmentModal.selectAttendee",
                      defaultMessage: "Select Attendee",
                    })}
                    fullWidth
                  />
                )}
                onChange={(_, value) => {
                  if (value?.id === "userData") {
                    const userData = isNewUser
                      ? values.user
                      : users.find((u) => u.id === values.user) || {};

                    const userAttendee = attendees.find(
                      (attendee) => attendee.isUser === true
                    );

                    if (userAttendee) {
                      setSelectedAttendee(userAttendee);
                      setFieldValue("attendee", userAttendee.id);
                      setIsNewAttendee(false);
                    } else {
                      setIsNewAttendee(true);
                      setSelectedAttendee(null);
                      setFieldValue("attendee", {
                        firstName:
                          (userData as any)?.firstname ||
                          (userData as any)?.firstName ||
                          "",
                        lastName:
                          (userData as any)?.lastname ||
                          (userData as any)?.lastName ||
                          "",
                        email: (userData as any)?.email || "",
                        phone: (userData as any)?.phone || "",
                        birthdate: "",
                        sex: "other",
                        postalCode: "",
                        permisStatus: "",
                      });
                    }
                  } else if (value) {
                    setSelectedAttendee(value as Attendee);
                    setFieldValue("attendee", value.id);
                    setIsNewAttendee(false);
                  } else {
                    setSelectedAttendee(null);
                    setFieldValue("attendee", null);
                    setIsNewAttendee(false);
                  }
                }}
                value={selectedAttendee}
                disabled={!selectedUser && !isNewUser}
              />
              <Button
                onClick={() => {
                  setIsNewAttendee(true);
                  setSelectedAttendee(null);
                  setFieldValue("attendee", {
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    birthdate: "",
                    sex: "other",
                    postalCode: "",
                    permisStatus: "",
                  });
                }}
                sx={{ mt: 1 }}
                disabled={!selectedUser && !isNewUser}
              >
                <FormattedMessage
                  id="enrollmentModal.createNewAttendee"
                  defaultMessage="Create New Attendee"
                />
              </Button>
            </>
          ) : (
            <>
              <Field
                as={LatinTextField}
                name="attendee.firstName"
                label={intl.formatMessage({
                  id: "signUp.firstName.input",
                })}
                fullWidth
                margin="normal"
                required
                validate={(value: any) =>
                  Yup.string()
                    .required(
                      intl.formatMessage({ id: "signUp.firstNameRequired" })
                    )
                    .validate(value)
                    .then(() => undefined)
                    .catch((err) => err.message)
                }
              />
              <Field
                as={LatinTextField}
                name="attendee.lastName"
                label={intl.formatMessage({
                  id: "signUp.lastName.input",
                })}
                fullWidth
                margin="normal"
                required
                validate={(value: any) =>
                  Yup.string()
                    .required(
                      intl.formatMessage({ id: "signUp.lastNameRequired" })
                    )
                    .validate(value)
                    .then(() => undefined)
                    .catch((err) => err.message)
                }
              />
              <Field
                as={TextField}
                name="attendee.birthdate"
                label={intl.formatMessage({
                  id: "admin.dashboard.birthdate",
                })}
                type="date"
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                inputProps={{ max: new Date().toISOString().split("T")[0] }}
                required
              />
              <Field as={RadioGroup} name="attendee.sex" row required>
                <FormControlLabel
                  value="female"
                  control={<Radio />}
                  label={intl.formatMessage({
                    id: "privateEnroll.female",
                  })}
                />
                <FormControlLabel
                  value="male"
                  control={<Radio />}
                  label={intl.formatMessage({
                    id: "privateEnroll.male",
                  })}
                />
                <FormControlLabel
                  value="other"
                  control={<Radio />}
                  label={intl.formatMessage({
                    id: "privateEnroll.other",
                  })}
                />
              </Field>
              <div style={{ margin: "16px 0" }}>
                <PhoneInput
                  international
                  countryCallingCodeEditable={false}
                  defaultCountry="US"
                  value={
                    (typeof values.attendee === "object" &&
                      values.attendee?.phone) ||
                    ""
                  }
                  onChange={(value) => setFieldValue("attendee.phone", value)}
                  inputComponent={CustomTextField as any}
                  error={
                    typeof values.attendee === "object" &&
                    errors.attendee?.phone
                  }
                />
                {errors.attendee?.phone && (
                  <Typography color="error" variant="caption">
                    {errors.attendee?.phone}
                  </Typography>
                )}
              </div>
              <Field
                as={EmailTextField}
                name="attendee.email"
                label={intl.formatMessage({ id: "signUp.email" })}
                fullWidth
                margin="normal"
                required
                validate={(value: any) =>
                  Yup.string()
                    .email(intl.formatMessage({ id: "signUp.invalidEmail" }))
                    .required(
                      intl.formatMessage({ id: "signUp.emailRequired" })
                    )
                    .validate(value)
                    .then(() => undefined)
                    .catch((err) => err.message)
                }
              />
              <Field
                as={TextField}
                name="attendee.postalCode"
                label={intl.formatMessage({
                  id: "signUp.postalCode",
                })}
                fullWidth
                margin="normal"
                required
                validate={(value: any) =>
                  Yup.string()
                    .test(
                      "is-valid-postal-code",
                      intl.formatMessage({
                        id: "attendees.invalidPostalCodeFormat",
                      }),
                      (value) => {
                        if (!value) return false;
                        return isValidPostalCode(value);
                      }
                    )
                    .required(
                      intl.formatMessage({ id: "signUp.postalCodeRequired" })
                    )
                    .validate(value)
                    .then(() => undefined)
                    .catch((err) => err.message)
                }
              />
              <div style={{ margin: "16px 0" }}>
                <FormControl
                  fullWidth
                  required
                  error={touched.permisStatus && Boolean(errors.permisStatus)}
                >
                  <InputLabel id="permisStatus-label">
                    {intl.formatMessage({
                      id: "attendee.permisStatus",
                    })}
                  </InputLabel>
                  <Select
                    labelId="permisStatus-label"
                    id="permisStatus"
                    label={intl.formatMessage({
                      id: "attendee.permisStatus",
                    })}
                    defaultValue=""
                    onChange={(e) => {
                      setFieldValue("attendee.permisStatus", e.target.value);
                    }}
                  >
                    <MenuItem value="PermisS">
                      {intl.formatMessage({
                        id: "attendee.permisStatus.permisS",
                      })}
                    </MenuItem>
                    <MenuItem value="Other">
                      {intl.formatMessage({
                        id: "attendee.permisStatus.other",
                      })}
                    </MenuItem>
                  </Select>
                  {touched.permisStatus && errors.permisStatus && (
                    <FormHelperText>{errors.permisStatus}</FormHelperText>
                  )}
                </FormControl>
              </div>
              <Button
                onClick={() => {
                  setIsNewAttendee(false);
                  setSelectedAttendee(null);
                  setFieldValue("attendee", null);
                }}
                sx={{ mt: 1 }}
              >
                <FormattedMessage
                  id="enrollmentModal.selectExistingAttendee"
                  defaultMessage="Select Existing Attendee"
                />
              </Button>
            </>
          )}
        </>
      ),
    },
    {
      title: intl.formatMessage({ id: "enrollmentModal.selectSchedules" }),
      content: (
        values: FormValues,
        setFieldValue: (field: string, value: any) => void,
        touched: any,
        errors: any
      ) => (
        <>
          <div style={{ marginBottom: "20px" }}>
            <Typography>
              {intl.formatMessage({ id: "admin.enrollmentModal.date" })}:
            </Typography>
            <TextField
              label={intl.formatMessage({ id: "admin.dashboard.date" })}
              type="date"
              value={date}
              InputLabelProps={{ shrink: true }}
              inputProps={
                classItem?.cancelDate
                  ? {
                      max: new Date(classItem?.cancelDate)
                        .toISOString()
                        .split("T")[0],
                    }
                  : {}
              }
              sx={{
                mr: 2,
                mb: isMobile ? 1 : 0,
                mt: 1,
                width: isMobile ? "100%" : "auto",
              }}
              onChange={(e) => setDate(e.target.value)}
            />
          </div>
          {renderSchedules(values.scheduleIds, setFieldValue)}
          <ErrorMessage name="scheduleIds" component="div" className="error" />
        </>
      ),
    },
  ];

  return (
    <Modal
      open={open}
      onClose={() => {
        clearAllStates();
        onClose();
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: isMobile ? "95%" : 600,
          maxHeight: "90vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: isMobile ? 2 : 4,
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            <FormattedMessage
              id="enrollmentModal.title"
              defaultMessage="Enroll Attendee"
            />
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => {
              clearAllStates();
              onClose();
            }}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, setFieldValue, isSubmitting }) => (
            <Form>
              {steps.map((step, index) => (
                <Accordion
                  key={index}
                  expanded={activeStep === index}
                  onChange={() => setActiveStep(index)}
                  disabled={
                    (index > activeStep &&
                      !validateStep(activeStep, values, errors)) ||
                    index - activeStep > 1
                  }
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{step.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {step.content(values, setFieldValue, touched, errors)}
                  </AccordionDetails>
                </Accordion>
              ))}
              {backendError && (
                <Alert severity="error" sx={{ mt: 2 }}>
                  {backendError}
                </Alert>
              )}
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: isMobile ? "column" : "row",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  onClick={() => setActiveStep((prev) => Math.max(0, prev - 1))}
                  disabled={activeStep === 0}
                  sx={{ mb: isMobile ? 1 : 0 }}
                  fullWidth={isMobile}
                >
                  <FormattedMessage id="common.back" defaultMessage="Back" />
                </Button>
                {activeStep < steps.length - 1 ? (
                  <Button
                    onClick={() => {
                      if (validateStep(activeStep, values, errors)) {
                        setActiveStep((prev) =>
                          Math.min(steps.length - 1, prev + 1)
                        );
                      }
                    }}
                    variant="contained"
                    color="primary"
                    sx={{ mb: isMobile ? 1 : 0 }}
                    fullWidth={isMobile}
                    disabled={!validateStep(activeStep, values, errors)}
                  >
                    <FormattedMessage id="common.next" defaultMessage="Next" />
                  </Button>
                ) : (
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    sx={{ mb: isMobile ? 1 : 0 }}
                    fullWidth={isMobile}
                    disabled={
                      isSubmitting || !validateStep(activeStep, values, errors)
                    }
                  >
                    <FormattedMessage
                      id="enrollmentModal.enroll"
                      defaultMessage="Enroll"
                    />
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
        <Snackbar
          open={isWaitlistAlert}
          autoHideDuration={6000}
          onClose={() => {
            setIsWaitlistAlert(false);
            modalClose();
            clearAllStates();
          }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => {
              setIsWaitlistAlert(false);
              modalClose();
              clearAllStates();
            }}
            severity="info"
          >
            <FormattedMessage
              id="enrollmentModal.waitlistAlert"
              defaultMessage="You've been added to the waiting list for some classes."
            />
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};

export default EnrollmentModal;
