import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  appleAuth,
  getProfile,
  googleAuth,
  logOut,
  loginAdmin,
  loginUser,
  registerUser,
  updateProfile,
  loginInstructor,
  changePassword,
} from "./authOperations";
import Cookies from "js-cookie";
import { UsersState } from "../types/types";

const tokenExists = !!Cookies.get("authToken");

const initialState: UsersState = {
  user: {
    id: null,
    email: null,
    firstname: null,
    lastname: null,
    phone: null,
    role: null,
    dataUsageAgreed: true,
    photoAgreed: false,
    attendees: [],
    classes: [],
  },
  loading: false,
  isAuthenticated: tokenExists,
  isAdmin: false,
  isInstructor: null,
  from: null,
  token: Cookies.get("authToken") || null,
  wantedClass: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
      state.user.role = action.payload ? "admin" : "user";
    },
    setInstructor: (state, action: PayloadAction<boolean>) => {
      state.isInstructor = action.payload;
      state.user.role = action.payload ? "instructor" : "user";
    },
    setFrom: (state, action) => {
      state.from = action.payload;
    },
    setAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    setWantedClass: (state, action: PayloadAction<number | null>) => {
      state.wantedClass = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(registerUser.fulfilled, (state: UsersState) => {
        state.isAuthenticated = true;
        state.loading = false;
        state.token = Cookies.get("authToken") || null;
        state.user.role = "user";
      })
      .addCase(registerUser.rejected, (state: UsersState) => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(loginUser.pending, (state: UsersState) => {
        state.loading = true;
        state.isAuthenticated = false;
      })
      .addCase(loginUser.fulfilled, (state: UsersState) => {
        state.loading = false;
        state.isAdmin = false;
        state.isAuthenticated = true;
        state.isInstructor = false;
        state.user.role = "user";
        state.token = Cookies.get("authToken") || null;
      })
      .addCase(loginUser.rejected, (state: UsersState) => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(getProfile.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state: UsersState, action: any) => {
        state.user = {
          ...state.user,
          ...action.payload,
          role: action.payload.isAdmin
            ? "admin"
            : action.payload.isInstructor
            ? "instructor"
            : "user",
        };
        state.isAdmin = action.payload.isAdmin;
        state.isInstructor = action.payload.isInstructor;
        state.isAuthenticated = true;
        state.loading = false;
        state.token = Cookies.get("authToken") || null;
      })
      .addCase(getProfile.rejected, (state: UsersState) => {
        state.loading = false;
        state.isAuthenticated = false;
      })
      .addCase(updateProfile.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(updateProfile.fulfilled, (state: UsersState) => {
        state.loading = false;
      })
      .addCase(updateProfile.rejected, (state: UsersState) => {
        state.loading = false;
      })
      .addCase(googleAuth.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(googleAuth.fulfilled, (state: UsersState) => {
        state.isAuthenticated = true;
        state.loading = false;
        state.token = Cookies.get("authToken") || null;
        state.user.role = "user";
      })
      .addCase(googleAuth.rejected, (state: UsersState) => {
        state.loading = false;
      })
      .addCase(appleAuth.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(appleAuth.fulfilled, (state: UsersState) => {
        state.isAuthenticated = true;
        state.loading = false;
        state.token = Cookies.get("authToken") || null;
        state.user.role = "user";
      })
      .addCase(appleAuth.rejected, (state: UsersState) => {
        state.loading = false;
      })
      .addCase(logOut.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(logOut.fulfilled, (state: UsersState) => {
        state.loading = false;
        state.user = initialState.user;
        state.isAdmin = false;
        state.isInstructor = false;
        state.isAuthenticated = false;
        state.token = null;
        state.from = null;
      })
      .addCase(logOut.rejected, (state: UsersState) => {
        state.loading = false;
      })
      .addCase(loginAdmin.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(loginAdmin.fulfilled, (state: UsersState) => {
        state.isAdmin = true;
        state.isInstructor = false;
        state.user.role = "admin";
        state.isAuthenticated = true;
        state.loading = false;
        state.token = Cookies.get("authToken") || null;
      })
      .addCase(loginAdmin.rejected, (state: UsersState) => {
        state.loading = false;
      })
      .addCase(loginInstructor.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(loginInstructor.fulfilled, (state: UsersState) => {
        state.isAdmin = false;
        state.isInstructor = true;
        state.user.role = "instructor";
        state.isAuthenticated = true;
        state.loading = false;
        state.token = Cookies.get("authToken") || null;
      })
      .addCase(loginInstructor.rejected, (state: UsersState) => {
        state.loading = false;
      })
      .addCase(changePassword.pending, (state: UsersState) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state: UsersState) => {
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state: UsersState) => {
        state.loading = false;
      });
  },
});

export const {
  setAdmin,
  setInstructor,
  setFrom,
  setAuthenticated,
  setWantedClass,
} = authSlice.actions;
export default authSlice.reducer;
