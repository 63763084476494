import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
  Button,
  Divider,
  DialogActions,
  useTheme,
  useMediaQuery,
  Box,
} from "@mui/material";
import {
  Close as CloseIcon,
  Restore as RestoreIcon,
} from "@mui/icons-material";
import { Attendee, Schedule } from "../../../redux/types/types";
import { useDispatch } from "react-redux";
import {
  createAttendeeAbsences,
  deleteAttendeeAbsences,
} from "../../../redux/enrollment/enrollmentOperations";
import { AppDispatch, RootState } from "../../../redux/store";
import { getProfile } from "../../../redux/auth/authOperations";
import { getActiveAttendees } from "../../MySchedules/MySchedules";
import { useSelector } from "react-redux";
import { deleteScheduleCancellation } from "../../../redux/admin/adminOperations";
import Notiflix from "notiflix";

interface EventDialogProps {
  isOpen: boolean;
  onClose: () => void;
  event: {
    id: string;
    title: string;
    start: Date;
    end: Date;
    extendedProps: {
      location: string;
      type: string;
      attendees: Attendee[];
      schedule: Schedule;
    };
  } | null;
}

export default function EventDialogInstructor({
  isOpen,
  onClose,
  event,
}: EventDialogProps) {
  const theme = useTheme();
  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const [attendanceState, setAttendanceState] = useState<{
    [key: number]: boolean;
  }>({});
  const [isEventStarted, setIsEventStarted] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);

  useEffect(() => {
    if (event) {
      const initialState = !event.extendedProps.schedule.date
        ? event.extendedProps.attendees.reduce((acc, attendee) => {
            acc[attendee.id] = !attendee.absences?.some((absence) => {
              return (
                new Date(absence.date).toDateString() ===
                event.start.toDateString()
              );
            });
            return acc;
          }, {} as { [key: number]: boolean })
        : !isAdmin
        ? event.extendedProps.attendees.reduce((acc, attendee) => {
            acc[attendee.id] = !attendee.absences?.some((absence: any) => {
              return (
                new Date(absence).toDateString() === event.start.toDateString()
              );
            });
            return acc;
          }, {} as { [key: number]: boolean })
        : event.extendedProps.schedule.AttendeeToSchedule
        ? event.extendedProps.schedule.AttendeeToSchedule.reduce((acc, ats) => {
            acc[ats.attendee.id] = !ats.AttendeeAbsence?.some(
              (absence: any) => {
                return (
                  new Date(absence.date).toDateString() ===
                  event.start.toDateString()
                );
              }
            );
            return acc;
          }, {} as { [key: number]: boolean })
        : {};

      setAttendanceState(initialState || {});

      const now = new Date();
      setIsEventStarted(now >= event.start);
    }
  }, [event, isAdmin]);

  const handleAttendanceChange = (attendeeId: number) => {
    if (isEventStarted) {
      setAttendanceState((prevState) => ({
        ...prevState,
        [attendeeId]: !prevState[attendeeId],
      }));
    }
  };

  const handleSaveAttendance = async () => {
    if (!event || !isEventStarted) return;

    //eslint-disable-next-line
    const [classId, scheduleId] = event.id.split("-");
    const absentAttendees = Object.entries(attendanceState)
      .filter(([_, isPresent]) => !isPresent)
      .map(([id]) => parseInt(id));
    const presentAttendees = Object.entries(attendanceState)
      .filter(([_, isPresent]) => isPresent)
      .map(([id]) => parseInt(id));

    if (absentAttendees.length > 0) {
      await dispatch(
        createAttendeeAbsences({
          scheduleId: parseInt(scheduleId),
          date: event.start,
          attendeeIds: absentAttendees,
        })
      );
    }

    if (presentAttendees.length > 0) {
      await dispatch(
        deleteAttendeeAbsences({
          scheduleId: parseInt(scheduleId),
          date: event.start,
          attendeeIds: presentAttendees,
        })
      );
    }

    dispatch(getProfile());
    onClose();
  };

  if (!event) return null;

  const weekdays = [
    intl.formatMessage({ id: "admin.dashboard.sunday" }),
    intl.formatMessage({ id: "admin.dashboard.monday" }),
    intl.formatMessage({ id: "admin.dashboard.tuesday" }),
    intl.formatMessage({ id: "admin.dashboard.wednesday" }),
    intl.formatMessage({ id: "admin.dashboard.thursday" }),
    intl.formatMessage({ id: "admin.dashboard.friday" }),
    intl.formatMessage({ id: "admin.dashboard.saturday" }),
  ];

  const formatTime = (date: Date) => {
    return intl.formatTime(date, {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    });
  };

  if (!event.extendedProps.schedule.date) {
    event.extendedProps.attendees = getActiveAttendees(
      event.start,
      event.extendedProps.schedule
    );
  }

  const handleRestore = async () => {
    const id = event.extendedProps.schedule.CancelledSchedule?.find(
      (cs) =>
        new Date(event?.start).setHours(0, 0, 0, 0) ===
        new Date(cs.date).setHours(0, 0, 0, 0)
    )?.id;

    if (typeof id === "number") await dispatch(deleteScheduleCancellation(id));
    Notiflix.Notify.success(
      intl.formatMessage({
        id: "admin.attendance.Restored",
        defaultMessage: "Restored",
      })
    );
    await dispatch(getProfile());
    await onClose();
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle sx={{ pr: 6 }}>
        <FormattedMessage
          id="mySchedules.attendance"
          defaultMessage="Attendance"
        />
        {", "}
        {intl.formatDate(event.start, {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          {weekdays[event.start.getDay()]}, {formatTime(event.start)}
          {" - "}
          {formatTime(event.end)}
        </Typography>
        {Boolean(
          event?.start &&
            Boolean(
              event.extendedProps.schedule.CancelledSchedule?.find(
                (cs) =>
                  new Date(event?.start).setHours(0, 0, 0, 0) ===
                  new Date(cs.date).setHours(0, 0, 0, 0)
              )
            )
        ) ? (
          <>
            <Box sx={{ display: "flex" }}>
              <Typography variant="h3" component="div" color={"red"}>
                {intl.formatMessage({
                  id: "upcomingClasses.Cancelled",
                  defaultMessage: "Cancelled",
                })}
              </Typography>
              {Boolean(
                new Date().setHours(0, 0, 0, 0) <
                  new Date(event?.start).setHours(0, 0, 0, 0)
              ) &&
                isAdmin && (
                  <Button onClick={handleRestore}>
                    <RestoreIcon sx={{ width: "50px", height: "50px" }} />
                  </Button>
                )}
            </Box>
            <Typography variant="h6" component="div">
              {
                event.extendedProps.schedule.CancelledSchedule?.find(
                  (cs) =>
                    new Date(event?.start).setHours(0, 0, 0, 0) ===
                    new Date(cs.date).setHours(0, 0, 0, 0)
                )?.comment
              }
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
              <FormattedMessage
                id="mySchedules.attendees"
                defaultMessage="Attendees"
              />
              :
            </Typography>
            <Grid container spacing={2}>
              {event.extendedProps.attendees.map(
                (attendee: Attendee, index: number) => (
                  <React.Fragment key={attendee.id}>
                    <Grid item xs={12} sm={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={attendanceState[attendee.id] ?? true}
                            onChange={() => handleAttendanceChange(attendee.id)}
                            disabled={!isEventStarted}
                          />
                        }
                        label={`${attendee.firstName} ${attendee.lastName}`}
                      />
                    </Grid>
                    {!isMobile &&
                      index % 2 === 1 &&
                      index !== event.extendedProps.attendees.length - 1 && (
                        <Grid item xs={12}>
                          <Divider />
                        </Grid>
                      )}
                  </React.Fragment>
                )
              )}
            </Grid>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveAttendance}
          disabled={!isEventStarted}
          fullWidth={isMobile}
        >
          <FormattedMessage id="common.save" defaultMessage="Save Attendance" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}
