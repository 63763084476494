import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  fetchTypes,
  deleteType,
  updateType,
  createType,
} from "../../../redux/admin/adminOperations";
import { Type } from "../../../redux/types/types";
import { useIntl } from "react-intl";
import Notiflix from "notiflix";

type Order = "asc" | "desc";

export const useTypeManagement = () => {
  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const { types, totalTypes, loading, error } = useSelector(
    (state: RootState) => state.admin
  );

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [typeToDelete, setTypeToDelete] = useState<number | null>(null);
  const [typeToEdit, setTypeToEdit] = useState<Type | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [orderBy, setOrderBy] = useState<keyof Type>("id");
  const [order, setOrder] = useState<Order>("asc");
  const [searchTerm, setSearchTerm] = useState("");
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const fetchTypeData = useCallback(() => {
    dispatch(
      fetchTypes({
        page: page + 1,
        limit: rowsPerPage,
        sortBy: orderBy,
        sortOrder: order,
        search: searchTerm,
      })
    );
  }, [dispatch, page, rowsPerPage, orderBy, order, searchTerm]);

  const handleDeleteType = useCallback((typeId: number) => {
    setTypeToDelete(typeId);
    setDeleteDialogOpen(true);
  }, []);

  const confirmDeleteType = useCallback(() => {
    if (typeToDelete) {
      dispatch(deleteType(typeToDelete))
        .unwrap()
        .then(() => {
          fetchTypeData();
          setDeleteDialogOpen(false);
          setTypeToDelete(null);
          setDeleteError(null);
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.deleteTypeSuccess" })
          );
        })
        .catch((error) => {
          let errorKey = "error.failedToDeleteType";
          if (error.message === "Type not found.") {
            errorKey = "error.typeNotFound";
          } else if (
            error.message === "Cannot delete type because it is in use."
          ) {
            errorKey = "error.typeInUse";
          } else if (error.message === "An unexpected error occurred.") {
            errorKey = "error.unexpectedError";
          }
          setDeleteError(errorKey);
        });
    }
  }, [typeToDelete, dispatch, fetchTypeData, intl]);

  const handleEditType = useCallback((type: Type) => {
    setTypeToEdit(type);
    setEditDialogOpen(true);
  }, []);

  const handleUpdateType = useCallback(() => {
    if (typeToEdit && typeToEdit.id) {
      dispatch(
        updateType({
          typeId: typeToEdit.id,
          typeData: typeToEdit,
        })
      )
        .unwrap()
        .then(() => {
          fetchTypeData();
          setEditDialogOpen(false);
          setTypeToEdit(null);
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.editTypeSuccess" })
          );
        })
        .catch((error) => {
          Notiflix.Notify.failure(
            intl.formatMessage({ id: "admin.editTypeFailure" })
          );
          console.error("Failed to update type:", error);
        });
    }
  }, [typeToEdit, dispatch, fetchTypeData, intl]);

  const handleCreateType = useCallback(
    (typeData: Omit<Type, "id">) => {
      dispatch(createType(typeData))
        .unwrap()
        .then(() => {
          fetchTypeData();
          setCreateDialogOpen(false);
          Notiflix.Notify.success(
            intl.formatMessage({ id: "admin.createTypeSuccess" })
          );
        })
        .catch((error) => {
          Notiflix.Notify.failure(
            intl.formatMessage({ id: "admin.createTypeFailure" })
          );
          console.error("Failed to create type:", error);
        });
    },
    [dispatch, fetchTypeData, intl]
  );

  const handleChangePage = useCallback((event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    },
    []
  );

  const handleRequestSort = useCallback(
    (property: keyof Type) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
      setPage(0);
    },
    []
  );

  return {
    types,
    totalTypes,
    loading,
    error,
    deleteDialogOpen,
    editDialogOpen,
    createDialogOpen,
    typeToDelete,
    typeToEdit,
    page,
    rowsPerPage,
    orderBy,
    order,
    searchTerm,
    deleteError,
    fetchTypeData,
    handleDeleteType,
    confirmDeleteType,
    handleEditType,
    handleUpdateType,
    handleCreateType,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleSearchChange,
    setDeleteDialogOpen,
    setEditDialogOpen,
    setCreateDialogOpen,
    setTypeToEdit,
    setDeleteError,
  };
};
