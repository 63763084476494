import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const RootRedirect = () => {
  const { isAuthenticated, isAdmin, isInstructor, from } = useSelector(
    (state: RootState) => state.auth
  );

  if (isAuthenticated) {
    if (from?.includes("/enroll")) {
      return <Navigate to={from} replace />;
    }

    if (isAdmin) {
      return <Navigate to="/admin/dashboard" replace />;
    } else if (isInstructor) {
      return <Navigate to="/instructor/dashboard" replace />;
    } else {
      return <Navigate to="/upcoming-classes" replace />;
    }
  } else {
    return <Navigate to="/auth/signin" replace />;
  }
};

export default RootRedirect;
