import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage, useIntl } from "react-intl";
import { Attendee, Schedule } from "../../../../../redux/types/types";
import AttendeeSchedulesModal from "../../../../MyClasses/AttendeesList/AttendeeSchedulesModal/AttendeeSchedulesModal";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

interface AttendeesListModalProps {
  open: boolean;
  onClose: () => void;
  attendees: Attendee[];
  classId: number;
  schedulesList: Schedule[];
  onAttendeeUnenroll: (updatedAttendees: Attendee[]) => void;
}

export default function AttendeesListModal({
  open,
  onClose,
  attendees,
  classId,
  schedulesList,
  onAttendeeUnenroll,
}: AttendeesListModalProps) {
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedAttendee, setSelectedAttendee] = useState<Attendee | null>(
    null
  );
  const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);
  const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
  const classDataAdmin = useSelector((state: RootState) =>
    state.admin.classes.find((c) => c.id === classId)
  );
  const classDataInstructor = useSelector((state: RootState) =>
    state.auth.user.classes.find((c) => c.id === classId)
  );

  const handleAttendeeClick = (attendee: Attendee) => {
    setSelectedAttendee(attendee);
    setScheduleModalOpen(true);
  };

  const calculateAge = (birthdate: Date): number => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleUnenroll = (unenrolledAttendee: Attendee) => {
    const updatedAttendees = attendees.filter(
      (a) => a.id !== unenrolledAttendee.id
    );
    onAttendeeUnenroll(updatedAttendees);
  };

  // const handlePartialUnenroll = (unenrolledAttendee: Attendee,schedules:number[]) => {
  //   const updatedAttendees = attendees.map(
  //     (a) =>
  //   );
  //   onAttendeeUnenroll(updatedAttendees);
  // };

  const exportToExcel = () => {
    const classData = isAdmin ? classDataAdmin : classDataInstructor;

    if (classData) {
      const worksheet = XLSX.utils.json_to_sheet(
        attendees.map((attendee) => ({
          "First Name": attendee.firstName,
          "Last Name": attendee.lastName,
          Age: calculateAge(attendee.birthdate),
          Sex:
            attendee.sex === "female"
              ? intl.formatMessage({ id: "privateEnroll.female" })
              : attendee.sex === "male"
              ? intl.formatMessage({ id: "privateEnroll.male" })
              : attendee.sex === "other"
              ? intl.formatMessage({ id: "privateEnroll.other" })
              : attendee.sex,
          Email: attendee.email,
          Phone: attendee.phone,
          "Agreed to photo/video": attendee.user?.photoAgreed,
          "Postal Code": attendee.postalCode || "",
          "Permis Status":
            attendee.permisStatus === "PermisS"
              ? intl.formatMessage({ id: "attendee.permisStatus.permisS" })
              : intl.formatMessage({ id: "attendee.permisStatus.other" }),
          "Class Title": classData.title.fr || classData.title.en,
          "Instructor Name": `${classData.instructors
            .map((i) => `${i.instructor.firstname} ${i.instructor.lastname}`)
            .join(", ")}`,
          Location: classData.location.name.fr || classData.location.name.en,
          Type: classData.type.name.fr || classData.type.name.en,
        }))
      );

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Attendees");

      XLSX.writeFile(
        workbook,
        `Attendees_List_${
          classData.title.fr || classData.title.en
        }_${new Date().toISOString()}.xlsx`
      );
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth="md"
        fullWidth
        fullScreen={isMobile}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormattedMessage
            id="admin.dashboard.attendeesList"
            defaultMessage="Attendees List"
          />
          <div>
            <Button onClick={exportToExcel} sx={{ mr: 1 }}>
              <FormattedMessage
                id="admin.dashboard.reports.exportExcel"
                defaultMessage="Export to Excel"
              />
            </Button>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <List
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "1fr",
                sm: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
              },
              gap: 2,
              padding: 2,
            }}
          >
            {attendees.map((attendee, index) => (
              <ListItem
                key={attendee.id}
                sx={{
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: 1,
                  padding: 2,
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "action.hover",
                  },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
                onClick={() => handleAttendeeClick(attendee)}
              >
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" component="div">
                      {`${index + 1}. ${attendee.firstName} ${
                        attendee.lastName
                      }`}
                    </Typography>
                  }
                  secondary={
                    <>
                      <Typography variant="body2" component="div">
                        {`${calculateAge(
                          attendee.birthdate
                        )} ${intl.formatMessage({ id: "attendee.yearsOld" })}`}
                      </Typography>
                      <Typography variant="body2" component="div">
                        {attendee.sex === "female"
                          ? intl.formatMessage({ id: "privateEnroll.female" })
                          : attendee.sex === "male"
                          ? intl.formatMessage({ id: "privateEnroll.male" })
                          : attendee.sex === "other"
                          ? intl.formatMessage({ id: "privateEnroll.other" })
                          : attendee.sex}
                      </Typography>
                      <Typography variant="body2" component="div">
                        {attendee.permisStatus === "PermisS"
                          ? intl.formatMessage({
                              id: "attendee.permisStatus.permisS",
                            })
                          : intl.formatMessage({
                              id: "attendee.permisStatus.other",
                            })}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      {selectedAttendee && (
        <AttendeeSchedulesModal
          listClose={onClose}
          open={scheduleModalOpen}
          onClose={() => setScheduleModalOpen(false)}
          attendee={{
            attendee: selectedAttendee,
            createdAt: new Date().toISOString(),
            unenrollDate: null,
          }}
          schedulesList={schedulesList}
          onUnenroll={handleUnenroll}
        />
      )}
    </>
  );
}
