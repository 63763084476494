import api from "./axiosInstance";

export const fetchSchedulesApi = (params: {
  classId: number;
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: "asc" | "desc";
}) => {
  return api.get("/admin/schedules", {
    params: params,
  });
};

export const deleteScheduleApi = (scheduleId: number) =>
  api.delete(`/admin/schedules/${scheduleId}`);

export const createScheduleApi = (scheduleData: any) =>
  api.post("/admin/schedules", scheduleData);

export const updateScheduleApi = (scheduleId: number, scheduleData: any) =>
  api.patch(`/admin/schedules/${scheduleId}`, scheduleData);

export const bulkCreateSchedulesApi = (schedules: any[]) => {
  return api.post("/admin/schedules/bulk", { schedules });
};

export const bulkUpdateSchedulesApi = (schedules: any[]) => {
  return api.patch("/admin/schedules/bulk", { schedules });
};

export const bulkDeleteSchedulesApi = (scheduleIds: number[]) => {
  return api.delete("/admin/schedules/bulk", {
    data: { ids: scheduleIds },
  });
};

export const cancelScheduleApi = (data: {
  scheduleId: number;
  date: Date;
  comment: string;
}) => api.post("/admin/schedules/cancel", data);

export const deleteScheduleCancellationApi = (id: number) =>
  api.delete(`/admin/schedules/cancelation/${id}`);
