import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  IconButton,
  TablePagination,
  Typography,
  Box,
  Chip,
  Collapse,
  Tooltip,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  PeopleAlt as PeopleAltIcon,
  Cancel as CancelIcon,
  Refresh as RefreshIcon,
  PersonAddAlt as AddAttendeeIcon,
} from "@mui/icons-material";
import { FormattedMessage, useIntl } from "react-intl";
import ScheduleList from "./ScheduleList";
import { Class, Attendee } from "../../../../redux/types/types";
import WaitingListModal from "../../../MyClasses/WaitingListModal/WaitingListModal";
import EnrollmentModal from "../../../MyClasses/EnrollmentModal/EnrollmentModal";
import AttendeesListModal from "./AttendeesListModal/AttendeesListModal";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import { cancelSchedule } from "../../../../redux/admin/adminOperations";
import CancelDialog from "./CancelDialog/CancelDialog";
import Notiflix from "notiflix";

interface ClassesListProps {
  classes: Class[];
  totalClasses: number;
  page: number;
  rowsPerPage: number;
  orderBy: keyof Class | any;
  order: "asc" | "desc";
  onRequestSort: (property: keyof Class) => void;
  onChangePage: (event: unknown, newPage: number) => void;
  onChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onEditClass: (classItem: Class) => void;
  onDeleteClass: (classId: number) => void;
  onSetClassInProgressOfCancellation: (
    classId: number,
    cancelDate: string
  ) => void;
  onReactivateClass: (classId: number) => void;
  fetchClassData: () => void;
  onClassesUpdate?: (updatedClasses: Class[]) => void;
}

const languages =
  process.env.REACT_APP_DEFAULT_LANGUAGE === "en"
    ? (["en", "uk", "fr"] as const)
    : (["fr", "uk", "en"] as const);
type Language = (typeof languages)[number];

const LocalizedContent: React.FC<{ content: Record<Language, string> }> = ({
  content,
}) => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    {languages.map(
      (lang) =>
        content[lang] && (
          <Box
            key={lang}
            sx={{ display: "flex", alignItems: "center", mb: 0.5 }}
          >
            <Chip
              label={lang.toUpperCase()}
              size="small"
              color="primary"
              variant="outlined"
              sx={{ width: 40, mr: 1, flexShrink: 0 }}
            />
            <Typography variant="body2" component="span">
              {content[lang]}
            </Typography>
          </Box>
        )
    )}
  </Box>
);

export default function ClassesList({
  classes,
  totalClasses,
  page,
  rowsPerPage,
  orderBy,
  order,
  onRequestSort,
  onChangePage,
  onChangeRowsPerPage,
  onEditClass,
  onDeleteClass,
  onSetClassInProgressOfCancellation,
  onReactivateClass,
  fetchClassData,
  onClassesUpdate,
}: ClassesListProps) {
  const [expandedClass, setExpandedClass] = useState<number | null>(null);
  const [isWaitingListModalOpen, setIsWaitingListModalOpen] = useState(false);
  const [selectedWaitingAttendees, setSelectedWaitingAttendees] = useState<
    Attendee[]
  >([]);
  const [classIdForWL, setClassIdForWL] = useState<number | null>(null);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [selectedClassId, setSelectedClassId] = useState<number | null>(null);
  const [selectedClass, setSelectedClass] = useState<Class | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAttendeesModalOpen, setIsAttendeesModalOpen] = useState(false);
  const [selectedClassForAttendees, setSelectedClassForAttendees] =
    useState<Class | null>(null);
  const [attendees, setAttendees] = useState<Attendee[]>([]);
  const [cancellClassError, setCancellClassError] = useState<string | null>(
    null
  );

  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();

  const createSortHandler =
    (property: keyof Class) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };

  const handleExpandClick = (classId: number) => {
    setExpandedClass(expandedClass === classId ? null : classId);
  };

  const handleCancelClick = (classId: number) => {
    setSelectedClassId(classId);
    setCancelDialogOpen(true);
  };

  const handleCancelDialogClose = () => {
    setCancelDialogOpen(false);
    setSelectedClassId(null);
    setCancellClassError(null);
  };

  const handleWaitingListClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    attendees: Attendee[],
    classId: number
  ) => {
    setSelectedWaitingAttendees(attendees);
    setIsWaitingListModalOpen(true);
    setClassIdForWL(classId);
  };

  const handleWaitingListClose = () => {
    setIsWaitingListModalOpen(false);
    setClassIdForWL(null);
  };

  const handleEnrollClick = (classItem: Class) => {
    setSelectedClass(classItem);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleAttendeesClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    classAttendees: Attendee[],
    classId: number,
    selectedClass: Class
  ) => {
    const enrolledAttendees = classAttendees.filter(
      (attendee) => !attendee.unenrollDate
    );
    setAttendees(enrolledAttendees);
    setSelectedClassForAttendees(selectedClass);
    setIsAttendeesModalOpen(true);
  };

  const handleAttendeesClose = () => {
    setIsAttendeesModalOpen(false);
    setSelectedClassForAttendees(null);
    setAttendees([]);
  };

  const handleAttendeeUnenroll = (updatedAttendees: Attendee[]) => {
    setAttendees(updatedAttendees);
    const updatedClasses = classes.map((c) =>
      c.id === selectedClassForAttendees?.id
        ? { ...c, attendees: updatedAttendees }
        : c
    );
    onClassesUpdate && onClassesUpdate(updatedClasses);
  };

  const handleEnrollmentSuccess = async () => {
    await fetchClassData();
  };

  function formatDateTimeRange(
    date: Date,
    startTime: string,
    endTime: string,
    intl: any
  ) {
    const startDate = new Date(date);
    const [startHour, startMinute] = startTime.split(":").map(Number);
    startDate.setHours(startHour, startMinute);

    const endDate = new Date(date);
    const [endHour, endMinute] = endTime.split(":").map(Number);
    endDate.setHours(endHour, endMinute);

    return intl.formatDateTimeRange(startDate, endDate, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hourCycle: "h23",
    });
  }

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === "title"}
                  direction={orderBy === "title" ? order : "asc"}
                  onClick={createSortHandler("title")}
                >
                  <FormattedMessage id="admin.dashboard.class.title" />
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.location" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.type" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.instructor" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.class.startDate" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.class.status" />
              </TableCell>
              <TableCell>
                <FormattedMessage id="admin.dashboard.actions" />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {classes.map((classItem) => (
              <React.Fragment key={classItem.id}>
                <TableRow>
                  <TableCell>
                    <IconButton
                      onClick={() => handleExpandClick(classItem.id)}
                      size="small"
                    >
                      {expandedClass === classItem.id ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <LocalizedContent content={classItem.title} />
                  </TableCell>
                  <TableCell>
                    <LocalizedContent content={classItem.location.name} />
                  </TableCell>
                  <TableCell>
                    <LocalizedContent content={classItem.type.name} />
                  </TableCell>
                  <TableCell>
                    {classItem.instructors
                      .map(
                        (i) =>
                          `${i.instructor.firstname} ${i.instructor.lastname}`
                      )
                      .join(", ")}
                  </TableCell>
                  <TableCell>
                    {classItem.schedules?.length === 1 &&
                    classItem.schedules[0]?.repeatWeekly === false
                      ? `${
                          classItem.schedules[0]?.date &&
                          formatDateTimeRange(
                            classItem.schedules[0]?.date,
                            classItem.schedules[0]?.startTime,
                            classItem.schedules[0]?.endTime,
                            intl
                          )
                        }`
                      : (classItem.startDate &&
                          `${intl.formatDate(classItem.startDate, {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })}`) ||
                        ""}
                  </TableCell>
                  <TableCell>
                    {classItem.status === "Active" && (
                      <Chip
                        label={intl.formatMessage({
                          id: "admin.dashboard.class.status.active",
                        })}
                        color="success"
                      />
                    )}
                    {classItem.status === "InProgressOfCancellation" && (
                      <Chip
                        label={intl.formatMessage({
                          id: "admin.dashboard.class.status.cancelling",
                        })}
                        color="warning"
                      />
                    )}
                    {classItem.status === "Cancelled" && (
                      <Chip
                        label={intl.formatMessage({
                          id: "admin.dashboard.class.status.cancelled",
                        })}
                        color="error"
                      />
                    )}
                    {(classItem.status === "Active" ||
                      classItem.status === "InProgressOfCancellation") && (
                      <Tooltip
                        title={intl.formatMessage({
                          id: "admin.dashboard.cancelClass",
                        })}
                      >
                        <IconButton
                          color="warning"
                          onClick={() => handleCancelClick(classItem.id)}
                          size="small"
                        >
                          <CancelIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                    {classItem.status === "Cancelled" && (
                      <Tooltip
                        title={intl.formatMessage({
                          id: "admin.dashboard.restoreClass",
                        })}
                      >
                        <IconButton
                          color="success"
                          onClick={() => onReactivateClass(classItem.id)}
                          size="small"
                        >
                          <RefreshIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <Box display="flex" flexDirection="column">
                      <Box mb={1}>
                        <Tooltip
                          title={intl.formatMessage({
                            id: "admin.dashboard.edit",
                          })}
                        >
                          <IconButton
                            color="primary"
                            onClick={() => onEditClass(classItem)}
                            size="small"
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={intl.formatMessage({
                            id: "admin.dashboard.delete",
                          })}
                        >
                          <IconButton
                            color="secondary"
                            onClick={() => onDeleteClass(classItem.id)}
                            size="small"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      <Box>
                        <Tooltip
                          title={intl.formatMessage({
                            id: "myClasses.enrollAttendee",
                          })}
                        >
                          <IconButton
                            color="success"
                            onClick={() => handleEnrollClick(classItem)}
                            size="small"
                          >
                            <AddAttendeeIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip
                          title={intl.formatMessage({
                            id: "admin.dashboard.attendees",
                          })}
                        >
                          <span>
                            <IconButton
                              color="success"
                              onClick={(event) =>
                                classItem.attendees &&
                                handleAttendeesClick(
                                  event,
                                  classItem.attendees,
                                  classItem.id,
                                  classItem
                                )
                              }
                              size="small"
                              disabled={
                                !classItem.attendees ||
                                classItem.attendees.length === 0
                              }
                            >
                              <PeopleAltIcon />
                              {classItem.attendees &&
                                classItem.attendees.length > 0 && (
                                  <Typography
                                    variant="caption"
                                    component="span"
                                    sx={{
                                      position: "absolute",
                                      top: -8,
                                      right: -8,
                                      backgroundColor: "primary.main",
                                      color: "primary.contrastText",
                                      borderRadius: "50%",
                                      width:
                                        16 *
                                        (classItem.attendees.length.toString()
                                          .length === 1
                                          ? classItem.attendees.length.toString()
                                              .length
                                          : classItem.attendees.length.toString()
                                              .length / 2),
                                      height: 16,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "start",
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {
                                      classItem.attendees.filter(
                                        (attendee) => !attendee.unenrollDate
                                      ).length
                                    }
                                  </Typography>
                                )}
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip
                          title={intl.formatMessage({
                            id: "admin.dashboard.waitingList",
                          })}
                        >
                          <span>
                            <IconButton
                              color="default"
                              onClick={(event) =>
                                handleWaitingListClick(
                                  event,
                                  classItem.waitingAttendees || [],
                                  classItem.id
                                )
                              }
                              size="small"
                              disabled={
                                !classItem.waitingAttendees ||
                                classItem.waitingAttendees.length === 0
                              }
                            >
                              <PeopleAltIcon />
                              {classItem.waitingAttendees &&
                                classItem.waitingAttendees.length > 0 && (
                                  <Typography
                                    variant="caption"
                                    component="span"
                                    sx={{
                                      position: "absolute",
                                      top: -8,
                                      right: -8,
                                      backgroundColor: "primary.main",
                                      color: "primary.contrastText",
                                      borderRadius: "50%",
                                      width:
                                        16 *
                                        (classItem.waitingAttendees.length.toString()
                                          .length === 1
                                          ? classItem.waitingAttendees.length.toString()
                                              .length
                                          : classItem.waitingAttendees.length.toString()
                                              .length / 2),
                                      height: 16,
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "start",
                                      fontSize: "0.75rem",
                                    }}
                                  >
                                    {classItem.waitingAttendees.length}
                                  </Typography>
                                )}
                            </IconButton>
                          </span>
                        </Tooltip>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                    colSpan={7}
                  >
                    <Collapse
                      in={expandedClass === classItem.id}
                      timeout="auto"
                      unmountOnExit
                    >
                      <Box sx={{ margin: 1 }}>
                        <ScheduleList
                          schedules={classItem.schedules}
                          classStartDate={classItem.startDate}
                        />
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={totalClasses}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelRowsPerPage={<FormattedMessage id="admin.dashboard.rowsPerPage" />}
      />
      <EnrollmentModal
        open={isModalOpen}
        modalClose={handleModalClose}
        onClose={() => setIsModalOpen(false)}
        classItem={selectedClass}
        onEnrollmentSuccess={handleEnrollmentSuccess}
      />
      <WaitingListModal
        open={isWaitingListModalOpen}
        onClose={handleWaitingListClose}
        waitingAttendees={selectedWaitingAttendees}
        classId={classIdForWL}
      />
      <AttendeesListModal
        open={isAttendeesModalOpen}
        onClose={handleAttendeesClose}
        attendees={attendees}
        classId={selectedClassForAttendees?.id || 0}
        schedulesList={selectedClassForAttendees?.schedules || []}
        onAttendeeUnenroll={handleAttendeeUnenroll}
      />
      <CancelDialog
        open={cancelDialogOpen}
        onClose={handleCancelDialogClose}
        cl={classes.find((c) => c.id === selectedClassId)}
        cancellClassError={cancellClassError}
        onSubmit={async (data) => {
          if (
            selectedClassId &&
            data.cancelDate &&
            data.cancellationType === "class"
          ) {
            onSetClassInProgressOfCancellation(
              selectedClassId,
              data.cancelDate
            );
            handleCancelDialogClose();
          }
          if (
            data.selectedSchedule &&
            data.cancelDate &&
            data.cancellationType === "time"
          ) {
            const [hours, minutes] = data.selectedSchedule.startTime
              .split(":")
              .map(Number);
            const dateWithTime = new Date(
              new Date(data.cancelDate).setHours(hours, minutes, 0, 0)
            );

            const res = (
              await dispatch(
                cancelSchedule({
                  scheduleId: data.selectedSchedule.id,
                  comment: data.comment,
                  date: dateWithTime,
                })
              )
            ).payload.message;

            if (res === "Invalid date") {
              await setCancellClassError(
                intl.formatMessage({
                  id: "admin.dashboard.class.wrongCancelDateError",
                })
              );
              return;
            }

            await data.handleClose();

            Notiflix.Notify.success(
              intl.formatMessage({
                id: "admin.dashboard.class.cancelSuccess",
              })
            );
            handleCancelDialogClose();
          }
        }}
      />
    </>
  );
}
