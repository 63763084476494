import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItem,
  Typography,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import { Class, Schedule } from "../../../../../redux/types/types";

interface CancelDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: {
    cancelDate: string;
    comment: string;
    cancellationType: "time" | "class";
    selectedSchedule: Schedule | null;
    handleClose: () => void;
  }) => void;
  cl: Class | undefined;
  cancellClassError?: string | null;
}

export default function CancelDialog({
  open,
  onClose,
  onSubmit,
  cl,
  cancellClassError,
}: CancelDialogProps) {
  const intl = useIntl();
  const [cancellationType, setCancellationType] = useState<"time" | "class">(
    "time"
  );
  const [cancelDate, setCancelDate] = useState("");
  const [comment, setComment] = useState("");
  const [selectedScheduleId, setSelectedScheduleId] = useState("");
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(
    null
  );

  const handleClose = () => {
    onClose();
    setCancelDate("");
    setComment("");
    setCancellationType("time");
    setSelectedScheduleId("");
    setSelectedSchedule(null);
  };

  const handleSubmit = async () => {
    if (cancellationType === "time" && cancelDate && selectedScheduleId) {
      await onSubmit({
        cancelDate,
        comment,
        cancellationType,
        selectedSchedule,
        handleClose,
      });
    }

    if (cancellationType === "class" && cancelDate) {
      await onSubmit({
        cancelDate,
        comment,
        cancellationType,
        selectedSchedule: null,
        handleClose,
      });
    }
  };

  const weekdays = [
    intl.formatMessage({ id: "days.monday" }),
    intl.formatMessage({ id: "days.tuesday" }),
    intl.formatMessage({ id: "days.wednesday" }),
    intl.formatMessage({ id: "days.thursday" }),
    intl.formatMessage({ id: "days.friday" }),
    intl.formatMessage({ id: "days.saturday" }),
    intl.formatMessage({ id: "days.sunday" }),
  ];

  useEffect(() => {
    if (selectedScheduleId) {
      setSelectedSchedule(
        cl?.schedules.find((s) => s.id === +selectedScheduleId) || null
      );
    }
    //eslint-disable-next-line
  }, [selectedScheduleId]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box>
        <Button onClick={() => setCancellationType("time")}>
          <FormattedMessage id="admin.dashboard.class.cancelTitleTimeTitle" />
        </Button>
        <Button
          onClick={() => setCancellationType("class")}
          disabled={cl?.status === "InProgressOfCancellation"}
        >
          <FormattedMessage id="admin.dashboard.class.cancelTitleClassTitle" />
        </Button>
      </Box>
      {cancellationType === "time" ? (
        <>
          <DialogTitle>
            <FormattedMessage id="admin.dashboard.class.cancelTitleTime" />
          </DialogTitle>
          <DialogContent>
            <RadioGroup
              value={selectedScheduleId}
              onChange={(e) => setSelectedScheduleId(e.target.value)}
            >
              {weekdays.map((day, index) => {
                const daySchedules = cl?.schedules.filter(
                  (s) => !s.date && s.dayOfWeek === index
                );
                if (!daySchedules || daySchedules.length === 0) return null;
                return (
                  <div key={index}>
                    <ListItem>{day}</ListItem>
                    {daySchedules.map((s) => (
                      <FormControlLabel
                        key={s.id}
                        value={s.id}
                        control={<Radio />}
                        label={`${s.startTime} - ${s.endTime}`}
                      />
                    ))}
                  </div>
                );
              })}
            </RadioGroup>
            <TextField
              margin="dense"
              id="cancelDate"
              label={intl.formatMessage({
                id: "admin.dashboard.class.cancelDate",
              })}
              type="date"
              fullWidth
              variant="outlined"
              value={cancelDate}
              onChange={(e) => setCancelDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
              inputProps={
                cl?.cancelDate
                  ? {
                      min: new Date().toISOString().split("T")[0],
                      max: new Date(cl.cancelDate).toISOString().split("T")[0],
                    }
                  : {
                      min: new Date().toISOString().split("T")[0],
                    }
              }
            />
            <TextField
              margin="dense"
              id="comment"
              label={intl.formatMessage({
                id: "admin.dashboard.class.comment",
              })}
              type="text"
              fullWidth
              variant="outlined"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
            {cancellClassError && (
              <Typography color="error">{cancellClassError}</Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button onClick={handleSubmit} color="primary">
              <FormattedMessage id="admin.dashboard.class.submitCancellation" />
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>
            <FormattedMessage id="admin.dashboard.class.cancelTitle" />
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="cancelDate"
              label={intl.formatMessage({
                id: "admin.dashboard.class.cancelDate",
              })}
              type="date"
              fullWidth
              variant="outlined"
              value={cancelDate}
              onChange={(e) => setCancelDate(e.target.value)}
              InputLabelProps={{ shrink: true }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button onClick={handleSubmit} color="primary">
              <FormattedMessage id="admin.dashboard.class.submitCancellation" />
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
