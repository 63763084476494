import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";

const PublicRoute = () => {
  const { isAuthenticated, from } = useSelector(
    (state: RootState) => state.auth
  );

  if (isAuthenticated && (!from || !from.includes("/enroll"))) {
    return <Navigate to="/upcoming-classes" replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
